<template>
    <b-container fluid v-if="employee">
        <b-row>
            <b-col md="12" class="align-items-center justify-content-center text-center">
                <h3>Podaci o radniku</h3>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="p-4 align-items-center justify-content-center">
                <table class="table b-table table-striped table-hover table-bordered text-center">
                    <thead>
                    <tr>
                        <td>
                            <b>Ime:</b> {{employee.first_name}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Prezime:</b> {{employee.last_name}}
                        </td>
                    </tr>
                    <tr v-if="employee.residence_address">
                        <td>
                            <b>Adresa prebivališta:</b> {{employee.residence_address}}
                        </td>
                    </tr>
                    <tr v-if="employee.residence_township.name">
                        <td>
                            <b>Općina prebivališta:</b> {{employee.residence_township.name}}
                        </td>
                    </tr>
                    <tr v-if="employee.current_address">
                        <td>
                            <b>Adresa boravišta:</b> {{employee.current_address}}
                        </td>
                    </tr>
                    <tr v-if="employee.current_township.name">
                        <td>
                            <b>Općina boravišta:</b> {{employee.current_township.name}}
                        </td>
                    </tr>
                    <tr v-if="employee.jmbg">
                        <td>
                            <b>JMBG:</b> {{employee.jmbg}}
                        </td>
                    </tr>
                    <tr v-if="employee.phone">
                        <td>
                            <b>Telefon:</b> {{employee.phone}}
                        </td>
                    </tr>
                    </thead>
                </table>
            </b-col>
            <b-col md="6" class="p-4 flex-center flex-column">
                <b-row class="p-2 w-75">
                    <router-link class="w-100" :to="$route.path + '/edit'">
                        <b-btn size="lg" variant="primary" class="w-100">
                            Uredi radnika
                        </b-btn>
                    </router-link>
                </b-row>
            </b-col>
        </b-row>
        <b-modal centered
                 id="deleteEmemployees"
                 title="Potvrda"
                 @ok="deleteEmployee"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati radnika?</p>
        </b-modal>
    </b-container>
</template>

<script>
    export default {
        name: 'employee',
        data() {
            return {
                employee: null,
            }
        },
        methods: {
            deleteEmployee: function() {
                axiOsta.delete(api.employees + this.employee.id).then(response => {
                    this.$router.push('/employees');
                })
            }
        },
        created() {
            axiOsta.get(api.employees + this.$route.params.id).then(response => {
                if (response.status === 200) {
                    this.employee = response.data;
                }
            })
        }

    }
</script>
